
import debounce from 'lodash/debounce'
import isObject from 'lodash/isObject'
import { mapActions, mapGetters } from 'vuex'
import DelayHydration from 'nuxt-delay-hydration/dist/runtime/components/DelayHydration'
import Browser from 'bowser'

import FeedbackButton from '~/components/general/elements/FeedbackButton'
import ScreenTooltipDisplay from '~/components/general/elements/Tooltip/ScreenTooltipDisplay'
import SearchPageSwitch from '~/components/layout/pageSwitch/SearchPageSwitch'
import Localizable from '~/configurations/mixins/Localizable'
import MTGBuylistPageSwitch from '~/components/layout/pageSwitch/MTGBuylistPageSwitch'
import MTGSinglesPageSwitch from '~/components/layout/pageSwitch/MTGSinglesPageSwitch'

import EventBus from '~/services/eventBus/EventBus'
import BannersLayout from '~/components/layout/BannersLayout'
import Breadcrumbs from '~/components/layout/Breadcrumbs'
import { waitTimeout } from '~/services/Helpers'
import LogService from '~/services/LogService'
import { getMetaMockData } from '~/services/network/utils/helper.convenience.axios'
import { setCommonPasswords } from '~/structures/FormFieldValidator'

const NavbarDrawer = () =>
  import('~/components/layout/navbarComponent/NavbarDrawer')
const NavbarComponent = () =>
  import('~/components/layout/navbarComponent/NavbarComponent')
const FooterComponent = () => import('~/components/layout/FooterComponent')
const AppModal = () => import('~/components/layout/modal/AppModal')
const SideNavigation = () =>
  import('~/components/layout/sideNavigation/SideNavigation')

export default {
  name: 'Default',
  components: {
    FeedbackButton,
    MTGSinglesPageSwitch,
    ScreenTooltipDisplay,
    MTGBuylistPageSwitch,
    BannersLayout,
    AppModal,
    NavbarComponent,
    NavbarDrawer,
    FooterComponent,
    Breadcrumbs,
    SearchPageSwitch,
    SideNavigation,
    DelayHydration,
  },
  mixins: [Localizable],
  provide() {
    return {
      getLoc: this.getLoc,
      getLocCurrency: this.getLocCurrency,
    }
  },
  middleware: ['cookiebot', 'gtm', 'authGuard', 'i18n'],
  props: {},
  data() {
    return {
      isNavbarDrawerIn: false,
      isHomepage: true,
      hasSideNavigation: true,
      hasSearchSwitch: false,
      hasMTGSinglesSwitch: false,
      hasMTGBuylistSwitch: false,
      isTabActive: true,
    }
  },
  head() {
    const ehubCampaignId = this.$config.analytics.ehubCampaignId
    const oThis = this

    return {
      meta: [
        {
          hid: 'facebook-validation',
          name: 'facebook-domain-verification',
          content: 'i3sq3ldzznxe8frtgqgqqpyu4k5mln',
        },
        {
          hid: 'facebook-app-id',
          name: 'fb:app_id',
          content: '3010951669172281',
        },
      ],
      script: [
        {
          hid: 'facebook-pixel',
          type: 'text/javascript',
          innerHTML: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '296550599029828');
              fbq('track', 'PageView');`,
        },
        {
          hid: 'heureka-review',
          type: 'text/javascript',
          once: true,
          skip: process.client,
          innerHTML:
            `/*<![CDATA[*/` +
            `var _hwq = _hwq || [];` +
            `_hwq.push(['setKey', '3609E2B81C22C66275CB0FEFAC634222']);_hwq.push(['setTopPos', '150']);_hwq.push(['showWidget', '22']);(function() {` +
            `var ho = document.createElement('script'); ho.type = 'text/javascript'; ho.async = true;` +
            `ho.src = 'https://cz.im9.cz/direct/i/gjs.php?n=wdgt&sak=3609E2B81C22C66275CB0FEFAC634222';` +
            `var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ho, s);` +
            `})();` +
            '/*]]>*/',
        },
        {
          hid: 'ehc-click',
          type: 'text/javascript',
          once: true,
          skip: process.client,
          innerHTML:
            `(function() {` +
            `var ehcjs = document.createElement('script');` +
            `ehcjs.id = 'ehcjs';` +
            `ehcjs.src = 'https://ehub.cz/system/scripts/click.js.php'; ehcjs.async = true;` +
            `ehcjs.defer = true;` +
            `ehcjs.addEventListener('load', function() {` +
            `var ehubClick = new EhubClick(); ehubClick.setCampaignId("${ehubCampaignId}");` +
            `ehubClick.process(); });` +
            `document.head.appendChild(ehcjs); })();`,
        },
      ],
      noscript: [
        {
          innerHTML:
            '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=296550599029828&ev=PageView&noscript=1"/>',
        },
      ],
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: oThis.$themeSettings.features.christmasTheme.enabled
            ? 'https://www.najada.games/img/christmas/christmas_bg_xl.webp'
            : 'https://www.najada.games/img/background_schema_2.webp',
          media: '(min-width: 768px)',
        },
      ],
      __dangerouslyDisableSanitizers: ['script'],
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authentication/isAuthenticated',
      lang: 'general/getLanguage',
      mainBanners: 'banner/homepageMain',
      sideBanners: 'banner/homepageSideTwo',
    }),
  },
  watch: {
    isTabActive: {
      handler(value) {
        if (value && process.client) {
          try {
            this.refreshCart({ slRefresh: false })
            this.refreshBuylistCart()
            this.getLastShoppingList()
          } catch (e) {
            LogService.warn(e)
          }
        }
      },
    },
    $route: {
      immediate: true,
      /**
       * @param {Route} route
       */
      handler(route) {
        this.isHomepage =
          'homepage' in route.meta && route.meta.homepage === true
        this.hasMTGSinglesSwitch = route.matched.some(
          (record) =>
            'mtgSinglesSwitch' in record.meta && record.meta.mtgSinglesSwitch
        )
        this.hasSearchSwitch = route.matched.some(
          (record) => 'searchSwitch' in record.meta
        )
        this.hasMTGBuylistSwitch = route.matched.some(
          (record) => record.name && record.name.startsWith('singles_guide_mtg_buylist')
        )
        this.hasSideNavigation = this.isSideNavigationAvailable(route)

        if (process.client) {
          if (isObject(route.query) && 'ehub' in route.query) {
            const d = new Date()
            d.setTime(d.getTime() + 24 * 60 * 60 * 1000)
            const expires = 'expires=' + d.toUTCString()
            document.cookie = `ehcId=${route.query.ehub};${expires};path=/`
          }
        }
      },
    },
  },
  beforeCreate() {
    // eslint-disable-next-line nuxt/no-globals-in-created
    if (process.client && window.__NUXT__ === undefined) {
      // eslint-disable-next-line nuxt/no-globals-in-created
      window.__NUXT__ = { fetch: {} }
    }
  },
  created() {
    if (process.client) {
      try {
        // eslint-disable-next-line nuxt/no-globals-in-created
        document.getElementsByTagName('html')[0].className =
          this.processBrowserString()
      } catch (e) {
        this.$log.error(e)
      }
    }
  },
  async mounted() {
    const oThis = this
    await this.$nextTick()

    window.onresize = debounce(
      () => {
        EventBus.windowSizeChanged(window.innerWidth, window.innerHeight)
      },
      150,
      {
        maxWait: 200,
        leading: true,
        trailing: true,
      }
    )
    document.onvisibilitychange = function () {
      oThis.isTabActive = document.visibilityState === 'visible'
    }
    setTimeout(function () {
      oThis.fetchCommonPasswords()
      const heurekaTab = document.getElementById('heurekaTableft')

      if (oThis.isStage && heurekaTab) {
        heurekaTab.style.display = 'none'
      } else if (heurekaTab) {
        heurekaTab.parentElement.style.zIndex = '99'
      }
    }, 5000)
  },
  methods: {
    ...mapActions({
      hello: 'general/hello',
      mountAuthToken: 'authentication/mountToken',
      refreshCart: 'cart/refreshCart',
      refreshBuylistCart: 'cartBuyup/refreshCart',
      fetchStatusMessage: 'general/fetchStatusMessage',
      fetchUserProfile: 'authentication/loadProfileDetail',
      getLastShoppingList: 'shoppingLists/getLastShoppingList',
    }),

    async fetchCommonPasswords() {
      try {
        const passwords = await getMetaMockData({ id: 'common-passwords' })

        await this.$nextTick()
        setTimeout(function () {
          setCommonPasswords(passwords.data)
        }, 0)
      } catch (e) {
        this.$log.error(e)
      }
    },

    processBrowserString() {
      const browser = Browser.getParser(
        window.navigator.userAgent || window.navigator.vendor || ''
      )
      let classString = ''

      if (browser) {
        // eslint-disable-next-line no-useless-escape
        const osString = `${browser.getOSName(true)}-${(
          browser.getOSVersion() || ''
        )
          .replace('.', '_')
          .replace('.', '_')}`
        // eslint-disable-next-line no-useless-escape
        const browserString = `${browser
          .getBrowserName(true)
          .replaceAll(' ', '-')}-${(browser.getBrowserVersion() || '')
          .replace('.', '_')
          .replace('.', '_')}`

        classString = `${osString} ${browserString}`
      }

      return classString
    },

    async runFeedbackAction(takeScreenshot = false, model = {}) {
      const oThis = this
      let screenshot

      if (takeScreenshot) {
        oThis.$modal.hide()
        await waitTimeout(310)
        try {
          screenshot = await oThis.takeScreenshot()
        } catch (e) {
          this.$log.error(e)
        }
      }

      this.$modal.showContactUsForm({
        placeholder: 'contact_us_content_feedback',
        screenshotable: true,
        blob: screenshot,
        modelData: model,
        listeners: {
          makeScreenshot(model) {
            oThis.runFeedbackAction(true, model)
          },
        },
      })
    },

    async takeScreenshot() {
      try {
        const mediaStream = await navigator.mediaDevices.getDisplayMedia()
        const track = mediaStream.getVideoTracks()[0]

        // Check if this device supports a picture mode...
        const captureDevice = new ImageCapture(track)
        let blob = null
        if (captureDevice) {
          const bitmap = await captureDevice.grabFrame()
          const canvas = document.createElement('canvas')
          canvas.width = window.innerWidth
          canvas.height = window.innerHeight
          const context = canvas.getContext('2d')
          context.drawImage(bitmap, 0, 0, window.innerWidth, window.innerHeight)
          const blobPromise = new Promise((resolve, reject) =>
            canvas.toBlob(resolve, 'image/png')
          )
          blob = await blobPromise
        }

        const tracks = mediaStream?.getTracks()
        tracks?.forEach((track) => track.stop())

        return blob
      } catch (e) {
        this.$log.error(e)
        return null
      }
    },

    isSideNavigationAvailable(route) {
      if ('sidebar' in route.meta && route.meta.sidebar === null) return false
      return route.matched.some((record) => 'sidebar' in record.meta)
    },

    async showNavbarDrawer() {
      this.isNavbarDrawerIn = true
      window.document.body.style.overflow = 'hidden'

      await this.$nextTick()
      await this.fetchUserProfile({
        updateDependencies: false,
      })
    },
    hideNavbarDrawer() {
      this.isNavbarDrawerIn = false

      if (process.client) {
        window.document.body.style.overflow = ''
      }
    },
  },
}
