
  import PageNotFound from '~/pages/404'

  export default {
    name: 'error',
    components: { PageNotFound },
    props: {
      error: Object
    },
    data () {
      return {}
    },
    computed: {},
    methods: {}
  }
