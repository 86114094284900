
  export default {
    name: 'plain',
    props: {},
    data () {
      return {}
    },
    computed: {},
    methods: {}
  }
